@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

.map-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lora', serif;
    width: 100%;  /* Забезпечує, що .map-page займає всю доступну ширину */
    max-width: 600px;  /* Обмежує максимальну ширину на 600px */
    margin: 0 auto;  /* Центрує блок на сторінці */
  }
      
    .hero {
        position: relative;
        width: 100%;
        }

        .hero img {
        width: 100%;
        height: auto;
        display: block; /* видаляє будь-які відступи навколо зображення */
        }

        .hero-content {
            position: absolute;
            top: 5%;  /* змініть відсоток, якщо потрібно вище або нижче розташування */
            left: 0;
            width: 100%;  /* забезпечує, що контент займає всю ширину */
            transform: translateY(-10%);  /* піднімає контент трохи вище, щоб він був у верхній частині зображення */
            text-align: center;
            color: white;
            padding: 0 10px;  /* додає невеликі відступи з боків для кращої читабельності на малих екранах */
        }
        .hero-content h1 {
          font-size: 28px;
        }
        .sub-title {
          font-family: 'Open Sans', sans-serif;
          padding: 0 25px;
          width: 100%;
          text-align: left;
          margin-top: 10px;
          margin-bottom: 10px;
      }
      
      .section-title {
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }
      
      .divider {
        width: 90%;
        border-top: 1px solid #ccc;
        margin: 10px auto;
      }

      .map-bg {
        position: relative;
        width: 100%;
        padding: 5px 40px;
      }      

      .map-bgz {
        position: relative;
      }  

      .map-marker {
        position: absolute;
        top: 45%; /* Відцентровано по вертикалі */
        left: 50%; /* Відцентровано по горизонталі */
        transform: translate(-50%, -50%); /* Точне відцентрування маркера */
        height: 56px; /* Висота маркера */
        width: 56px; /* Ширина маркера */
        background-image: url('../assets/marker.png'); /* Шлях до вашого зображення маркера */
        background-size: cover;
        pointer-events: none; /* Вимикаємо події миші для маркера, щоб можна було керувати картою під маркером */
        z-index: 1000;
      }

      .map-container, .download-button, .order-button {
        width: 90%;
        margin: 0 auto;
      }
      
      .download-button {
        padding: 10px 20px;
        font-size: 18px;
        font-weight: 700;
        color: white;
        background-color: #ce4241;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 15px;
      }

      .order-button {
        padding: 20px 20px;
        font-size: 28px;
        font-weight: 700;
        color: white;
        background-color: #ce4241;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 15px;
      }
      
      .download-button:hover, .order-button:hover {
        background-color: #ce4241;
      }
      
      .instructions {
        padding: 15px;
        text-align: center;
        background-color: #fff;
        width: 100%;
      }

      .footer-image-container {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }
    
    .footer-image {
        width: 100%;
        height: auto;
    }
    
    .footer {
        width: 100%;
        min-height: 100px;
        background-color: #333;
        color: white;
        text-align: center;
        padding: 10px 0;
        margin-top: 20px;
    }