* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body, html {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  #root, .App {
    height: 100%;
  }
  
  .main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Початок контейнера */
    background: black;
    color: white;
    padding: 20px;
    min-height: 100vh;
    background-image: url('../assets/rightArrow.png'), url('../assets/leftArrow.png'); /* Додати фонові зображення */
    background-position: left top, right top; /* Розміщення зображень */
    background-repeat: no-repeat; /* Заборонити повторення */
    background-size: contain; /* Масштабування зображень */
  }
  
  .logo {
    width: 100px; /* Розмір лого */
    margin-top: 0; /* Прибрати додатковий відступ */
  }
  
  .offer-card {
    background: rgba(0, 0, 0, 0.5); /* Прозорий чорний фон */
    border: 1px solid #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin: 5px 0;
    width: 100%;
    max-width: 600px;
    text-align: center;
    text-decoration: none; /* Відключити підкреслення для посилань */
    color: white; /* Колір тексту посилань */
    transition: background 0.3s, transform 0.3s;
  }
  
  .offer-card:hover {
    background: rgba(0, 0, 0, 0.7); /* Підсвічування при наведенні */
    transform: scale(1.02); /* Легка зміна масштабу */
  }
  
  .offer-card h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .offer-card p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .offer-button {
    background: #00ffff;
    color: black;
    padding: 25px 40px; /* Збільшено розмір кнопки */
    border-radius: 5px;
    text-decoration: none;
    font-weight: 600;
    font-size: 24px;
    display: inline-block;
    margin-top: 10px;
  }
  
  .offer-button:hover {
    background: #00cccc;
  }